import seastar from './seastar.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={seastar} className="App-logo" alt="logo" />
        <p>
          Find the closest tidepools and the best times to go.
        </p>
      </header>
      <main className="DocumentBody">
        <p>This application is running in <b>{process.env.NODE_ENV}</b></p>
        <p>This application is talking to <b>{process.env.REACT_APP_API_URL}</b></p>

        <button onClick={() => {
          fetch(process.env.REACT_APP_API_URL)
          .then(response => response.text())
          .then(text => {
            console.log(text);
          })
        }}>
          Call API
        </button>
      </main>
    </div>
  );
}

export default App;
